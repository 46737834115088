<template>
    <div class="con_text">
        <p>트렌디언서 모집 페이지입니다.</p>
    </div>
    <Footer />
</template>

<script>
import Footer from "@/components/Footer";

export default {
components: {
    Footer,
  },
}
</script>

<style>
    .con_text{
        text-align: center;
        width:90%;
        margin:0 auto;
    }
    .con_text p{
        margin:27rem auto 20rem auto;
        font-size:var(--font-big);
    }
</style>